import React from 'react';

import Layout from '../components/layout';
import Footer from '../components/Footer';
import syedHeadshot from '../images/syed-headshot.jpeg';
import Sidebar from '../components/Sidebar';
import Scroll from '../components/Scroll';

const IndexPage = () => (
  <Layout>
    <Sidebar />

    <div id="wrapper">
      <section id="intro" className="wrapper style1 fullscreen fade-up">
        <div className="inner">
          <h1>Sell Tech by Building Tech</h1>
          <p>
            A coding bootcamp customized for sales professionals in Silicon
            Valley
          </p>
          <ul className="actions">
            <li>
              <Scroll type="id" element="one">
                <a href="#one" className="button">
                  Learn more
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
      </section>

      <section id="one" className="wrapper style3 fade-up">
        <div className="inner">
          <h2>Curriculum</h2>
          <p>
            Over 6 weeks, learn the skills you need to sell software by becoming
            well-versed in programming languages, web technologies, and
            technical communication. Work 1-on-1 with a senior full-stack
            engineer and previous bootcamp instructor within your schedule.
          </p>
          <div className="features">
            <section>
              <span className="icon major fa-code-fork" />
              <h3>Terminal, Git, and Code Editors</h3>
              <p>
                Week 1 - Learn the fundamental tools engineers use to create and
                maintain excellent software
              </p>
            </section>
            <section>
              <span className="icon major fa-code" />
              <h3>Javascript</h3>
              <p>
                Week 2 - Understand the programming language used to build most
                modern frontend (and backend) applications
              </p>
            </section>
            <section>
              <span className="icon major fa-desktop" />
              <h3>Build your own website</h3>
              <p>
                Week 3 - Build your own professional website from scratch, then
                again with Gatsby (used to build this site!) to make the whole
                process ridiculously easy
              </p>
            </section>
            <section>
              <span className="icon major fa-chain" />
              <h3>Add integrations</h3>
              <p>
                Week 4 - Learn how to connect your website to dozens of services
                like Prismic (content management) and Stripe (payment
                automation)
              </p>
            </section>
            <section>
              <span className="icon major fa-cog" />
              <h3>Automate your workflow</h3>
              <p>
                Week 5 - Write scripts to automate your workflow and discover
                new clients. Save hundreds of hours avoiding manual work
              </p>
            </section>
            <section>
              <span className="icon major fa-diamond" />
              <h3>Master your product</h3>
              <p>
                Week 6 - Every piece of software is different! Learn the
                ins-and-outs of your product so you can masterfully discuss your
                services with customers
              </p>
            </section>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper style2 spotlights">
        <section>
          <a className="image" alt="image">
            <img src={syedHeadshot} alt="" data-position="center center" />
          </a>
          <div className="content">
            <div className="inner">
              <h2>Syed Mohsin</h2>
              <h3>Software Engineer | Bootcamp Instructor</h3>
              <p>
                After getting my BS/MS in Computer Engineering from Columbia
                University, I spent over 6+ years as a full-stack software
                engineer, bootcamp instructor, and freelance developer. At
                BuildZoom, I worked directly with dozens of sales reps who
                inspired me to build this program.
              </p>
              <ul className="icons">
                <li>
                  <a
                    href="https://github.com/syed-mohsin"
                    target="_blank"
                    className="fa fa-github"
                  ></a>
                </li>

                <li>
                  <a
                    href="https://www.linkedin.com/in/syed-mohsin-a11aa326"
                    target="_blank"
                    className="fa fa-linkedin"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>

      <section id="three" className="wrapper style1 fade-up">
        <div className="inner">
          <h2>Get in touch</h2>
          <p>
            If you are interested or have any questions, call / shoot me a
            message so we can begin discussing how to best tailor this bootcamp
            for you.
          </p>
          <div className="split style1">
            <section>
              <form
                name="contact"
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
              >
                <input type="hidden" name="bot-field" />
                <div className="fields">
                  <div className="field half">
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" id="name" />
                  </div>
                  <div className="field half">
                    <label htmlFor="email">Email</label>
                    <input type="text" name="email" id="email" required />
                  </div>
                  <div className="field">
                    <label htmlFor="message">Message</label>
                    <textarea name="message" id="message" rows="5" />
                  </div>
                </div>
                <ul className="actions">
                  <li>
                    <button type="submit" className="button submit">
                      Send Message
                    </button>
                  </li>
                </ul>
              </form>
            </section>
            <section>
              <ul className="contact">
                <li>
                  <h3>Address</h3>
                  <span>
                    301 Howard St
                    <br />
                    San Francisco, CA 94105
                    <br />
                  </span>
                </li>
                <li>
                  <h3>Email</h3>
                  <a href="mailto:smohsin@buildzoom.com">
                    smohsin@buildzoom.com
                  </a>
                </li>
                <li>
                  <h3>Phone</h3>
                  <a href="tel:4156581779">415-658-1779</a>
                </li>
                <li>
                  <h3>Social</h3>
                  <ul className="icons">
                    <li>
                      <a
                        href="https://github.com/syed-mohsin"
                        target="_blank"
                        className="fa-github"
                      >
                        <span className="label">GitHub</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.linkedin.com/in/syed-mohsin-a11aa326"
                        className="fa-linkedin"
                      >
                        <span className="label">LinkedIn</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default IndexPage;
